import { PublicLayout } from "../../components/layout"
import { Helmet } from 'react-helmet';

//css files
import '../../css/main.css' //main css file

const News = () => {
    return (
        <PublicLayout>
             <Helmet>
                <title>News</title>
             </Helmet>
            <div className="content">
            <h1>News</h1>
            <p>No news to show.</p>
            </div>
        </PublicLayout>

        
    );
};
  
  export default News