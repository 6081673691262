import { Helmet } from "react-helmet";
import { useEffect, useState } from 'react'


//css files and photos
import '../../css/main.css' //main css file
import background from '../../assets/greyBackground.png'
import { fetchApiStatus, fetchApiStatusInfo } from "../../api/status";

import { PublicLayout } from "../../components/layout";


const Home = () => {
    const [status, setStatus] = useState(null)
  
    //fetch status and store in state.
    const GetStatus = async () => {
        try {
          const { data } = await fetchApiStatusInfo()
    
          setStatus(data.status)
    
        } catch (error) {
            console.log(error)
        }
    }
        
      useEffect(() => {
        GetStatus()
      }, [])


      return (
        <>
        <PublicLayout>
            <Helmet>
                <title>ZwickNet</title>
            </Helmet>
        <div className="hero-image" style={{backgroundImage: `url(${background})`}}>
            <div className="hero-box">
                <h1>Welcome</h1>


                
                <p class="hero-box-warning">This website is currently under development! May not function as expected!</p>
                <p class="hero-box-warning">ZwickNet API is currently: {status ? status : "offline"} </p>
            </div>

        </div>
        <div className="content"><h1>Welcome</h1>
            <hi>ZwickNet is currently under construction. If you need to contact the website owner, please visit <a href="/contact">this</a> page.</hi>
            <h1>installed api url: {process.env.REACT_APP_API_URL}</h1>

        </div>


        </PublicLayout>
        </>
        
    );
};
  
  export default Home