import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'
import { useState } from 'react'

//PageNotFound if user is logged in
const PageNotFoundWithAuth = () => {
    return (
        <div>
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>The link you followed is probably broken or the page has been removed.</p>

            <li><Link to="/">Home</Link></li>
            <li><Link to="/dashboard">Dashboard</Link></li>
        </div>
    );
};

//            <li><Link to="javascript:history.back()">Go Back</Link></li>

//PageNotFou   nd if user is not logged in
const PageNotFoundNoAuth = () => {
    return (
        <div>
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>The link you followed is probably broken or the page has been removed.</p>

            <li><Link to="/">Home</Link></li>
        </div>
    );
};

//            <li><Link to="javascript:history.back()">Go Back</Link></li>


//checks if user is logged in and serves them the correct component. (they just have different buttons)
const PageNotFound = () => {
    const { isAuth } = useSelector((state) => state.auth)
  
    return <>{isAuth ? <PageNotFoundWithAuth /> : <PageNotFoundNoAuth/>}</>
  }

export default PageNotFound
  