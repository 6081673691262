import { PublicLayout } from "../../components/layout";
import { Helmet } from 'react-helmet';

//css files
import '../../css/main.css' //main css file

const Contact = () => {
    return (
        <PublicLayout>
            <Helmet>
                <title>Contact | ZwickNet</title>
            </Helmet>
            <div className="content">
            <h1>Contact</h1>
            <hi>Email: contact@zwick.au</hi>
            <p></p>
            </div>
        </PublicLayout>
    );
};
  
  export default Contact