import axios from 'axios'
axios.defaults.withCredentials = true

export async function onRegistration(registrationData) {
  return await axios.post(
    (`${process.env.REACT_APP_API_URL}`+`/v1/users/register`),
    registrationData
  )
}

export async function onLogin(loginData) {
  return await axios.post((`${process.env.REACT_APP_API_URL}`+`/v1/users/login`), loginData)
}

export async function onLogout() {
  return await axios.get((`${process.env.REACT_APP_API_URL}`+`/v1/users/logout`))
}

export async function fetchProtectedInfo() {
  return await axios.get((`${process.env.REACT_APP_API_URL}`+`/v1/users/protected-info`))
}

//