import { React } from 'react';
import { useSelector } from 'react-redux'


import '../css/footer.css'

const CopyrightFooter = () => {

    const { isAuth } = useSelector((state) => state.auth)


return (
    <div className='copyrightFooter'>
            <div>
                <p>©2023-2024 All Rights Reserved</p>
            </div>
    </div>
  )
}

export default CopyrightFooter