import { React } from 'react';
import { useSelector } from 'react-redux'


import '../css/navbar.css'
import waveLogo from '../assets/zwicknetCutOffWavelogo.png'

const TopNavbar = () => {

    const { isAuth } = useSelector((state) => state.auth)


return (
    <nav className='topNavbar'>
        <div className=''>
            {isAuth ? (
                <div>
                    <div className='topNavbarLeft'>
                        <a href="/dashboard" className='topNavbarDashLink'>Dashboard |</a>
                        <a href="/dashboard/apps" className='topNavbarDashLink'>Applications |</a>
                    </div>
                    <div className='topNavbarRight'>
                        <a href="/users/logout" className='topNavbarLink'>Logout</a>
                    </div>
                </div>
            ) : (<></>)}
        </div>
    </nav>
  )
}

export default TopNavbar