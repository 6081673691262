import { React } from 'react';

import '../css/navbar.css'
import waveLogo from '../assets/zwicknetCutOffWavelogo.png'

const NavbarSimple = () => {

  return (
    <nav className='navbarX'>
      <div className=''>
        <div>
            <a href="/">
            <img 
              src={waveLogo}
              alt="ZwickNet Logo"
              className="logo">
            </img>
            </a>
            <a href="/" className='logoText'>ZwickNet</a>
        </div>
      </div>
    </nav>
  )
}

export default NavbarSimple