import { React } from 'react';

import '../css/dashboard.css'
import waveLogo from '../assets/zwicknetCutOffWavelogo.png'

const DashboardNavbar = () => {

  return (
    <nav className='dashboardNavbar'>
      <div className=''>
        <div>
            <a href="/">
            <img 
              src={waveLogo}
              alt="ZwickNet Logo"
              className="logo">
            </img>
            </a>
            <a href="/" className='logoText'>ZwickNet</a>
        </div>
        <p style={{"color": "white"}}>API:
          <span>
            <img src="https://status.zwick.au/api/badge/45/status" width="4%" style={{"max-width": "100px"}} alt="a"></img>
          </span>
          (delayed)  
        </p>
      </div>
    </nav>
  )
}

export default DashboardNavbar