import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchProtectedInfo, onLogout } from '../../api/auth'
import { DashboardLayout } from '../../components/layout'
import { unauthenticateUser } from '../../redux/slices/authSlice'

import '../../css/dashboard.css'

const Dashboard = () => {
  const [loading, setLoading] = useState(true)
  const [protectedData, setProtectedData] = useState(null)
  const dispatch = useDispatch()

  const protectedInfo = async () => {
    try {
      const { data } = await fetchProtectedInfo()

      setProtectedData(data.info)

      setLoading(false)
    } catch (error) {
      logout()
    }
  }

  const logout = async () => {
    try {
      await onLogout()

      dispatch(unauthenticateUser())
      localStorage.removeItem('isAuth')
    } catch (error) {
      console.log(error.response)
    }
  }

  useEffect(() => {
    protectedInfo()
  }, [])

  return loading ? (
    <DashboardLayout>
      <h1>Loading...</h1>
    </DashboardLayout>
  ) : (
    <div className='dashboard'>
      <DashboardLayout>
        <div className='dashboard'>
          <h1>Dashboard</h1>
          <p>{protectedData}</p>
        </div>
      </DashboardLayout>
    </div>
  )
}

export default Dashboard