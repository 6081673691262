import { onLogout } from '../api/auth'
import { useDispatch } from 'react-redux'
import { unauthenticateUser } from '../redux/slices/authSlice'

import '../css/main.css'
import NavbarSimple from '../components/navbarSimple';


const LogoutPage = () => {
    const dispatch = useDispatch()



    const logout = async () => {
        try {
          await onLogout()
    
          dispatch(unauthenticateUser())
          localStorage.removeItem('isAuth')
        } catch (error) {
          console.log(error.response)
        }
      }

    return (
      <>
        <NavbarSimple/>
        <div className='logout-page'>
            <h1>Logout</h1>
            <p>Are you sure you want to logout?</p>
            <a href='#' onClick={logout}>
              <span>Yes </span>
            </a>
            <br/>
            <a href='javascript:history.back()'>
               Go Back
            </a>
        </div>
      </>
    );
};
  
  export default LogoutPage