import { React } from 'react';
import { useSelector } from 'react-redux'

import '../css/navbar.css'
import waveLogo from '../assets/zwicknetCutOffWavelogo.png'

const Navbar = () => {

  const { isAuth } = useSelector((state) => state.auth)

  return (
    <nav className='navbarX'>
      <div className=''>
        <div>
            <a href="/">
            <img 
              src={waveLogo}
              alt="ZwickNet Logo"
              className="logo">
            </img>
            </a>
            <a href="/" className='logoText'>ZwickNet</a>
        </div>

        {isAuth ? (
          <div>
            
            <a href='/contact' className='navbarLink'>
              <span>Contact</span>
            </a>

            <a href='/news' className='navbarLink'>
              <span>News</span>
            </a>

            <a href='https://status.zwick.au' target='blank' className='navbarLink'>
              <span>Status</span>
            </a>

            <a style={{color: "white"}} className='navbarLink'>
              <span>|</span>
            </a>


            <a href='/dashboard' style={{float: "left"}} className='navbarDashLink'>
              <span>Dashboard</span>
            </a>

          </div>
        ) : (
          <div>
             <a href='/contact' className='navbarLink'>
              <span>Contact</span>
            </a>

            <a href='/news' className='navbarLink'>
              <span>News</span>
            </a>
            
            <a href='https://status.zwick.au' target='blank' className='navbarLink'>
              <span>Status</span>
            </a>
            
            <a href='/users/login'  className='navbarButton'>
              <span>Login</span>
            </a>

            <a href='/users/register' style={{float: "right"}} className='navbarLink'>
              <span>Register</span>
            </a>
          </div>
        )}
      </div>
    </nav>
  )
}

export default Navbar