//import stuff for routes
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom'

//import pages
import Home from './pages/public-pages/home.js'
import News from './pages/public-pages/news.js'
import Contact from './pages/public-pages/contact.js'

import Dashboard from './pages/dashboardPages/dashboard'
import MyApps from './pages/dashboardPages/myApps'

import Login from './pages/login'
import Register from './pages/register'
import LogoutPage from './pages/logout.js'

import PageNotFound from './pages/error-pages/404page'
//route components

import { useSelector } from 'react-redux'

//non logged in users will go to login page.
const PrivateRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth)

  return <>{isAuth ? <Outlet /> : <Navigate to='/users/login' />}</>
}

//already logged in users will go to dashboard.
const RestrictedRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth)

  return <>{!isAuth ? <Outlet /> : <Navigate to='/dashboard' />}</>
}

//already logged out users will go to home page.
const LogoutRoutes = () => {
  const { isAuth } = useSelector((state) => state.auth)

  return <>{isAuth ? <Outlet /> : <Navigate to='/' />}</>
}

//app
const App = () => {
  console.log(process.env.REACT_APP_API_URL);
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />

        <Route element={<RestrictedRoutes />}>
          <Route path='/users/login' element={<Login />} />
          <Route path='/users/register' element={<Register />} />
        </Route>

        <Route element={<LogoutRoutes />}>
          <Route path='/users/logout' element={<LogoutPage />} />
        </Route>
        
        <Route element={<PrivateRoutes />}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/dashboard/apps' element={<MyApps />} />
        </Route>

        <Route path='/news' element={<News />} />
        <Route path='/contact' element={<Contact />} />
        
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
