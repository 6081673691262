import TopNavbar from './topNavbar'
import Navbar from './navbar'
import NavbarSimple from './navbarSimple'
import PublicFooter from './publicFooter'
import CopyrightFooter from './copyrightFooter'
import DashboardNavbar from './dashboardNavbar'


export function SimpleLayout({ children }) {
  return (
    <div>
      <TopNavbar />
      <NavbarSimple/>
      <div>{children}</div>
      <CopyrightFooter />
    </div>
  )
}

export function PublicLayout({ children }) {
  return (
    <div>
      <TopNavbar />
      <Navbar/>
      <div>{children}</div>
      <PublicFooter/>
      <CopyrightFooter/>
    </div>
  )
}

export function DashboardLayout({ children }) {
  return (
    <div>
      <TopNavbar />
      <DashboardNavbar/>
      <div>{children}</div>
      <CopyrightFooter />
    </div>
  )
}
